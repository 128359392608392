import * as React from 'react';
import Layout from '../components/layout';
import styled from 'styled-components';
import { colors } from '../styles';

const Form = styled.form`
  label {
    font-size: 1.2rem;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #171717;
  color: white;
  font-size: 1.2rem;

  &:focus-visible {
    outline: 2px solid ${colors.accent};
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  background-color: ${colors.accent};
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.2rem;

  &:hover {
    background-color: ${colors.accentHover};
  }

  &:focus-visible {
    /* outline: 2px solid white; */
    background-color: ${colors.accentHover};
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #171717;
  color: white;
  font-size: 1.2rem;

  &:focus-visible {
    outline: 2px solid ${colors.accent};
  }
`;

const Heading = styled.div`
  text-align: center;
  margin-bottom: 50px;
`;

const ContactPage = () => (
  <Layout title="Contact Us" rel="/contact" maxWidth="32rem">
    <Heading>
      <h1>Contact Us</h1>
      <p>Feel free to reach out to us if you have any inquiries.</p>
    </Heading>

    <Form
      id="contact"
      name="contact"
      method="POST"
      data-netlify="true"
      action="/?contact=success"
    >
      <p>
        <label>
          Name:
          <Input type="text" name="name" />
        </label>
      </p>
      <p>
        <label>
          Email: <Input type="email" name="email" />
        </label>
      </p>
      <p>
        <label>
          Message: <TextArea name="message" rows={10} />
        </label>
      </p>
      <p>
        <SubmitButton type="submit" form="contact" value="Submit">
          Send
        </SubmitButton>
      </p>
      <input name="form-name" value="contact" type="hidden" />
    </Form>
  </Layout>
);

export default ContactPage;
