const size = {
  xs: '320px',
  sm: '768px',
  // lg: '1200px',
};

const device = {
  xs: `(min-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  // lg: `(min-width: ${size.lg})`,
};

const accent = '#1d9cee';

const colors = {
  accent: '#1982c6',
  accentHover: '#1d9cee',
};

export { size, device, colors };
